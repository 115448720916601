import React from 'react'
import { IIconProps } from './type'

const CarouselLeftCheveron = ({ color = 'white', height = '10px', width = '6px' }: IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9L1 5L5 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CarouselLeftCheveron
