import { Location } from '@/assets'
import HotelLocality from '@/assets/icons/HotelLocality'
import LocationLocality from '@/assets/icons/LocationLocality'
import Country from '@/assets/icons/country'
import Poi from '@/assets/icons/poi'
import { PREPARED_DATES } from '@/constants/date'
import { SUGGESTION_TYPE } from '@/constants/pages/home'
import { IflightsRecentSearch, IRecentSearchData, ISuggestion, QueryObj } from '@/types/home'
import { isServer } from '@/utils/general'
import { isEmpty } from '@/utils/general/browserHelper'
import { getFormattedDate } from '@/utils/general/date'

const storageName = 'fsHistory'

function filterValidCityNameflights(flights: IRecentSearchData[]) {
    return flights.filter(
        flight => flight?.destination?.trim()?.length > 0 || flight?.destinationCountry?.trim()?.length > 0
    )
}

export function getflightsRecentSearch() {
    if (!isServer()) {
        const recentSearch: IflightsRecentSearch = JSON.parse(window.localStorage.getItem(storageName) ?? '{}')
        const flights = recentSearch?.en || []

        if (!isEmpty(flights) && flights.length) {
            const idxArr = []

            // check flights with present date greater than checkin date
            for (let i = 0; i < flights.length; i++) {
                if (
                    new Date(getFormattedDate(flights[i].depart_date, 'MM/DD/YYYY')).getTime() <
                    new Date(getFormattedDate(PREPARED_DATES.TODAY, 'MM/DD/YYYY')).getTime()
                ) {
                    idxArr.push(i)
                }
            }

            if (idxArr.length > 1) {
                flights.splice(0, 3)
            } else if (idxArr.length === 1) {
                flights.splice(idxArr[0], 1)
            }
            idxArr.splice(0, 3)
            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        }

        if (recentSearch) {
            return filterValidCityNameflights(recentSearch?.en ?? [])?.map(flight => ({
                ...flight
            }))
        }
    }
    return []
}

// TODO: refactor raghav create lru cache here
export function updateflightsSearch(searchObj: QueryObj) {
    if (!isServer()) {
        const recentSearch = JSON.parse(localStorage.getItem(storageName) ?? '{}')
        searchObj.destinationType = 'recentSearch'
        if ((searchObj.cy?.trim()?.length ?? 0) <= 0 && (searchObj.s?.trim()?.length ?? 0) <= 0 && !searchObj.id) return

        if (!isEmpty(recentSearch)) {
            const flights = recentSearch?.flight?.value?.ns_rs
            if (flights && flights.length) {
                for (let i = 0; i < flights.length; i++) {
                    if (
                        searchObj.id && flights?.[i].id
                            ? flights?.[i].id == searchObj.id
                            : flights?.[i].cy == searchObj.cy
                    ) {
                        flights.splice(i, 1)
                        break
                    }
                }
            }

            flights?.unshift({ ...searchObj })

            if (flights && flights?.length >= 3) {
                flights.length = 3
            }
            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        } else {
            const recentSearch = { flight: { value: { ns_rs: [searchObj], ns_rvd: [] }, e: 0 } }
            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        }
    }
}

/**
 *
 * @param city :city in string;
 * @returns : stored recent searched flight item.
 *  This method will return the searhed item.
 */
export function getSearchedIteByCity(city: string) {
    let item
    if (!isServer()) {
        const recentSearch = JSON.parse(localStorage.getItem(storageName) ?? '{}')

        if (!isEmpty(recentSearch)) {
            const flights = recentSearch?.flight?.value?.ns_rs
            if (flights && flights.length) {
                for (let i = 0; i < flights.length; i++) {
                    if (flights?.[i].cy === city) {
                        item = flights?.[i]
                        break
                    }
                }
            }
        }
    }

    return item
}

export const renderIcon = (type: string) => {
    switch (type) {
        case SUGGESTION_TYPE.flight:
            return <HotelLocality className="dropdown-new__item-fill--icon listItemHover" />
        case SUGGESTION_TYPE.CITY:
            return <LocationLocality className="dropdown-new__item-fill--icon listItemHover" />
        case SUGGESTION_TYPE.LOCALITY:
            return <LocationLocality className="dropdown-new__item-fill--icon listItemHover" />
        case SUGGESTION_TYPE.COUNTRY:
            return <Country className="dropdown-new__item-fill--icon listItemHover" />
        case SUGGESTION_TYPE.STATE:
        case SUGGESTION_TYPE.REGION:
        case SUGGESTION_TYPE.AREA:
        case SUGGESTION_TYPE.ROAD:
            return <LocationLocality className="dropdown-new__item-fill--icon listItemHover" />

        case SUGGESTION_TYPE.POI:
            return <Poi className="dropdown-new__item-fill--icon listItemHover" />

        default:
            return <Location className="dropdown-new__item-stroke--icon listItemHover" />
    }
}
