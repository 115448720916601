import * as React from 'react'

const Poi = (props: React.SVGProps<SVGSVGElement> = {}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2C9.77 2 4 3.44 4 10C4 14.69 11.35 21.58 11.66 21.87C11.6837 21.8937 11.7075 21.905 11.7362 21.9185C11.7437 21.9221 11.7516 21.9258 11.76 21.93C11.7679 21.9339 11.7742 21.9394 11.7802 21.9447C11.7894 21.9527 11.7979 21.96 11.81 21.96C11.87 21.98 11.93 22 12 22C12.07 22 12.13 21.99 12.19 21.96C12.21 21.96 12.23 21.94 12.24 21.93C12.2512 21.9225 12.2638 21.9164 12.2768 21.9102C12.2985 21.8997 12.3212 21.8888 12.34 21.87C12.65 21.58 20 14.7 20 10C20 3.44 14.23 2 12 2ZM12 20.81C10.55 19.4 5 13.73 5 10C5 3.19 11.93 3 12 3C12.07 3 19 3.19 19 10C19 13.72 13.45 19.39 12 20.81ZM9.87752 12.7963C10.0375 12.9063 10.2175 12.9663 10.3875 12.9663C10.5475 12.9663 10.7075 12.9163 10.8475 12.8263L12.0075 12.0863L13.1575 12.8263C13.4575 13.0263 13.8175 13.0163 14.1175 12.7963C14.4175 12.5863 14.5475 12.2563 14.4675 11.8863L14.1475 10.4563L15.2175 9.48626C15.4775 9.23626 15.5675 8.89626 15.4575 8.55626C15.3375 8.20626 15.0675 7.98626 14.6975 7.95626L13.3375 7.83626L12.7975 6.50626C12.5175 5.82626 11.4775 5.83626 11.2075 6.50626L10.6675 7.83626L9.30752 7.95626C8.93752 7.98626 8.65752 8.20626 8.54752 8.55626C8.43752 8.89626 8.51752 9.23626 8.79752 9.49626L9.85752 10.4563L9.53752 11.8963C9.45752 12.2563 9.58752 12.5863 9.87752 12.7963ZM13.4175 11.8063L11.9975 10.8963H11.9775L10.5575 11.8063L10.9375 10.0963L9.64752 8.92626L11.3475 8.77626L11.9875 7.20626L12.6275 8.77626L14.3275 8.92626L13.0375 10.0963L13.4175 11.8063Z"
            fill="#1A1A1A"
        />
    </svg>
)

export default Poi
