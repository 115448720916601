import * as React from 'react'

const HotelLocality = (props: React.SVGProps<SVGSVGElement> = {}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 4.5C7.5 4.22386 7.72386 4 8 4H16C16.2761 4 16.5 4.22386 16.5 4.5V20H14.5V16C14.5 15.1716 13.8284 14.5 13 14.5H11C10.1716 14.5 9.5 15.1716 9.5 16V20H7.5V4.5ZM10.5 21H9.5H7H3.5C3.22386 21 3 20.7761 3 20.5V9C3 8.17157 3.67158 7.5 4.5 7.5H6.5V4.5C6.5 3.67157 7.17157 3 8 3H16C16.8284 3 17.5 3.67157 17.5 4.5V10.5H19.5C20.3284 10.5 21 11.1716 21 12V20.5C21 20.7761 20.7761 21 20.5 21H17H14.5H13.5H10.5ZM17.5 20H20V12C20 11.7239 19.7761 11.5 19.5 11.5H17.5V20ZM6.5 8.5V20H4V9C4 8.72386 4.22386 8.5 4.5 8.5H6.5ZM13.5 20H10.5V16C10.5 15.7239 10.7239 15.5 11 15.5H13C13.2761 15.5 13.5 15.7239 13.5 16V20ZM11.2828 5.21716C11.3579 5.29217 11.4 5.39391 11.4 5.5V7.5C11.4 7.72091 11.2209 7.9 11 7.9H9C8.77909 7.9 8.6 7.72091 8.6 7.5V5.5C8.6 5.27909 8.77909 5.1 9 5.1H11C11.1061 5.1 11.2078 5.14214 11.2828 5.21716ZM10.6 5.9L9.4 5.9V7.1H10.6V5.9ZM11.4 9.5C11.4 9.39391 11.3579 9.29217 11.2828 9.21716C11.2078 9.14214 11.1061 9.1 11 9.1H9C8.77909 9.1 8.6 9.27909 8.6 9.5V11.5C8.6 11.7209 8.77909 11.9 9 11.9H11C11.2209 11.9 11.4 11.7209 11.4 11.5V9.5ZM9.4 9.9H10.6V11.1H9.4V9.9ZM15.2828 5.21716C15.3579 5.29217 15.4 5.39391 15.4 5.5V7.5C15.4 7.72091 15.2209 7.9 15 7.9H13C12.7791 7.9 12.6 7.72091 12.6 7.5L12.6 5.5C12.6 5.27909 12.7791 5.1 13 5.1H15C15.1061 5.1 15.2078 5.14214 15.2828 5.21716ZM14.6 5.9L13.4 5.9V7.1H14.6L14.6 5.9ZM15.4 9.5C15.4 9.39391 15.3579 9.29217 15.2828 9.21716C15.2078 9.14214 15.1061 9.1 15 9.1H13C12.7791 9.1 12.6 9.27909 12.6 9.5L12.6 11.5C12.6 11.7209 12.7791 11.9 13 11.9H15C15.2209 11.9 15.4 11.7209 15.4 11.5V9.5ZM13.4 9.9H14.6L14.6 11.1H13.4V9.9Z"
            fill="#1A1A1A"
        />
    </svg>
)

export default HotelLocality
