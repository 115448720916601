import React from 'react'

export const PicturePlaceHolder = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 100 100" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M90.67 84.96H30.696v-8.59l9.227-21.47 13.841 17.18 12.984-13.31 16.694-6.03 7.228 16.11v16.11zm5.521-45.1h-72.35c-2.103 0-3.808 1.8-3.808 4.01v47.11c0 2.21 1.705 4 3.808 4h72.35c2.104 0 3.809-1.79 3.809-4V43.87c0-2.21-1.705-4.01-3.809-4.01zm-10.567-9.02L75.396 2.67C74.641.59 72.425-.52 70.451.21L2.464 25.02c-1.976.72-2.966 2.99-2.211 5.07L11.037 59.8V43.87c0-7.18 5.744-13.03 12.804-13.03h17.964L63.23 15.8l12.399 15.04h9.995z"
            ></path>
        </svg>
    )
}
