import React from 'react'
import { Spacer } from '@cleartrip/ct-design-components'
import { isEmpty } from '@/utils/general/browserHelper'

// Define types for the placeholder items
interface PlaceHolderItem {
    type: 'link' | 'bold'
    placeHolder: string
    replaceText: string
    url?: string
}

// Define types for the ParagraphText data prop
interface ParagraphTextData {
    value?: string
    placeHoldersList?: PlaceHolderItem[]
}

// Define props for ParagraphText component
interface ParagraphTextProps {
    data?: ParagraphTextData
}

// Define types for list item
interface ListItem {
    type: 'text' | 'list' | 'space'
    values?: ListItem[]
    placeHoldersList?: PlaceHolderItem[]
    value?: string
}

// Define props for ListCreator component
interface ListCreatorProps {
    data: { values?: ListItem[] }
}

// Define types for FAQ item
interface FaqItem {
    heading: string
    values: ListItem[]
}

// Define props for HomePageFaq component
interface HomePageFaqProps {
    data: FaqItem[]
    showFlightsFaq: boolean
}

const cleartripHomeFaq: FaqItem[] = [
    {
        heading: 'What are the best offers available for travel bookings on Cleartrip?',
        values: [
            {
                type: 'text',
                value: 'Get the best offers on hotels and flights using bank cards such as HDFC, ICICI, Bank of Baroda, Federal Bank, etc. You can also grab amazing discounts during our exciting sale events. Apart from this, find unmissable deals and offers on hotel stays and flight bookings throughout the year.'
            }
        ]
    },
    {
        heading: 'Are there any offers for new users on Cleartrip?',
        values: [
            {
                type: 'text',
                value: 'Firsts are always special. So new users, you get {FLAT 12% off on your airfare} and {up to 18% off on hotels} using code {CTFIRST}. Cleartrip is here to make your {online flight booking} seamless and simple.',
                placeHoldersList: [
                    {
                        type: 'bold',
                        replaceText: 'FLAT 12% off on your airfare',
                        placeHolder: '{FLAT 12% off on your airfare}'
                    },
                    {
                        type: 'bold',
                        replaceText: 'up to 18% off on hotels',
                        placeHolder: '{up to 18% off on hotels}'
                    },
                    {
                        type: 'link',
                        url: '/offers/india/dom-ctfirst',
                        replaceText: 'CTFIRST',
                        placeHolder: '{CTFIRST}'
                    },
                    {
                        type: 'link',
                        url: '/flights',
                        replaceText: 'online flight booking',
                        placeHolder: '{online flight booking}'
                    }
                ]
            }
        ]
    },
    {
        heading: 'How can I find the best deals and discounts on flights and hotels online on Cleartrip?',
        values: [
            {
                type: 'text',
                value: 'With Cleartrip, there are multiple deals available for your online flight and {hotel booking}. Whenever you select a hotel or a flight ticket, you can find the best available deals mentioned on the booking page. You can then use any of the available deals and coupon codes to avail your offer. Explore all {flight booking discount offers} & {hotels booking discount offers}.',
                placeHoldersList: [
                    {
                        type: 'link',
                        url: '/hotels',
                        replaceText: 'hotel booking',
                        placeHolder: '{hotel booking}'
                    },
                    {
                        type: 'link',
                        url: '/offers/india/hotels',
                        replaceText: 'hotels booking discount offers',
                        placeHolder: '{hotels booking discount offers}'
                    },
                    {
                        type: 'link',
                        url: '/offers/india/domestic-flights',
                        replaceText: 'flight booking discount offers',
                        placeHolder: '{flight booking discount offers}'
                    }
                ]
            }
        ]
    },
    {
        heading: 'Which is the best time to book flight tickets online?',
        values: [
            {
                type: 'text',
                value: 'There’s no specific right time to book your travel. With Cleartrip, there are deals and offers available on online flight tickets all year round, to make your booking easy and affordable. However, there are many exciting sale events Cleartrip has that offer the best deals on travel such as {Travel Maxx sale, #NoEndWeekend sale, Big Billion Days, Big Travel Sale,} etc. Stay tuned and keep an eye on when the travel sales are and book hotels and flights online for the best price!',
                placeHoldersList: [
                    {
                        type: 'bold',
                        replaceText: 'Travel Maxx sale, #NoEndWeekend sale, Big Billion Days, Big Travel Sale,',
                        placeHolder: '{Travel Maxx sale, #NoEndWeekend sale, Big Billion Days, Big Travel Sale,}'
                    }
                ]
            }
        ]
    }
]

const flightsFaq: FaqItem[] = [
    {
        heading: 'How to search and book cheap flights on Cleartrip?',
        values: [
            {
                type: 'text',
                value: 'Looking for flights and booking flight tickets is simple and seamless on Cleartrip.'
            },
            {
                type: 'list',
                values: [
                    {
                        type: 'text',
                        value: 'Enter source and destination city/airport'
                    },
                    {
                        type: 'text',
                        value: 'Select the date of travel'
                    },
                    {
                        type: 'text',
                        value: 'Choose the number of travellers'
                    }
                ]
            },
            {
                type: 'space'
            },
            {
                type: 'text',
                value: 'Hit enter and there you go! You have a search list of all the flights available, sorted according to price. You can further filter your search by choosing preferences and filters like time, duration, number of stops, and by airlines or even look for other dates simply by clicking on the calendar on the right side of the page.'
            }
        ]
    },
    {
        heading: 'How to make flexible flight bookings with changeable dates?',
        values: [
            {
                type: 'text',
                value: 'While making your flight booking, make sure to select the ‘ClearChoice Plus’ or ‘ClearChoice Max’ option before you confirm the air ticket. At a minimal cost, this allows you to modify your flight booking dates and airlines. So in case of any change in plans, Cleartrip has got you covered!'
            }
        ]
    },
    {
        heading: 'How to cancel flights online on Cleartrip?',
        values: [
            {
                type: 'text',
                value: 'In case you wish to cancel your booking due to any reason, simply -'
            },
            {
                type: 'list',
                values: [
                    {
                        type: 'text',
                        value: 'Select the trip you want to cancel'
                    },
                    {
                        type: 'text',
                        value: 'Click on the “Cancellations” link'
                    },
                    {
                        type: 'text',
                        value: 'Select the passengers to cancel the booking for. Then hit “Review cancellation”'
                    },
                    {
                        type: 'text',
                        value: 'Review passenger selection and refund amount'
                    },
                    {
                        type: 'text',
                        value: 'Click on “Yes, cancel now”'
                    }
                ]
            },
            {
                type: 'space'
            },
            {
                type: 'text',
                value: 'That’s it – you’re done! Sit back and wait for your refund that’s guaranteed to be processed within 24 hours.'
            },
            {
                type: 'text',
                value: 'While making your flight booking, select the ‘ClearChoice Max’ option before you confirm the air ticket, to cancel flight bookings without having to pay hefty cancellation charges!'
            }
        ]
    },
    {
        heading: 'What are the benefits of booking flights online with Cleartrip?',
        values: [
            {
                type: 'text',
                value: 'Get the best flight fares with exciting flight offers on your air ticket when you book with Cleartrip. Unmissable sales and deals like Travel Max Sale, Big Travel Sale, Cleartrip Tatkaal, etc. offer never-seen-before discounts that help you book flights at affordable rates. Best flight discounts await you when you book with bank cards like ICICI, Bank of Baroda, HDFC, Axis, Kotak etc.'
            }
        ]
    }
]

export const ParagraphText: React.FC<ParagraphTextProps> = ({ data }) => {
    const renderText = (): string => {
        let { value = '', placeHoldersList = [] } = data || {}
        if (value && !isEmpty(placeHoldersList)) {
            value = placeHoldersList.reduce((text, item) => {
                const { type, placeHolder, replaceText, url } = item
                if (type === 'link') {
                    return text.replace(
                        placeHolder,
                        `<a href=${url} class="c-secondary-500 td-none hover:td-underline fs-14">${replaceText}</a>`
                    )
                }
                if (type === 'bold') {
                    return text.replace(placeHolder, `<span class="fs-14 fw-600 c-neutral-900">${replaceText}</span>`)
                }
                return text
            }, value)
        }
        return value
    }

    return <p className="c-dark-grey fs-14" dangerouslySetInnerHTML={{ __html: renderText() }}></p>
}

export const ListCreator: React.FC<ListCreatorProps> = ({ data }) => {
    return (
        <ul className="px-6 pt-2">
            {data.values?.map((listItem, index) => {
                if (listItem.type === 'text') {
                    return (
                        <li key={index} className="pt-1">
                            <ParagraphText data={listItem} />
                        </li>
                    )
                }
                return null
            })}
        </ul>
    )
}

const HomePageFaq = ({ showFlightsFaq }: { showFlightsFaq: boolean }) => {
    const data = showFlightsFaq ? flightsFaq : cleartripHomeFaq
    return (
        <>
            {data.map((faq, index) => (
                <div key={index} className="pt-8">
                    <h3 className="pb-4 fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
                        {faq.heading}
                    </h3>
                    {faq.values.map((item, subIndex) => {
                        if (item.type === 'text') {
                            return <ParagraphText key={subIndex} data={item} />
                        }
                        if (item.type === 'list') {
                            return <ListCreator key={subIndex} data={item} />
                        }
                        if (item.type === 'space') {
                            return <Spacer key={subIndex} className="pt-4" />
                        }
                        return null
                    })}
                </div>
            ))}
        </>
    )
}

export default HomePageFaq
