import React from 'react'
import Image from 'next/image'
import { Container, Typography } from '@cleartrip/ct-design-components'
import { getPopularDestinationUrl } from '@/utils/general/url'
import { IPopularDestinations } from '@/types/home'
import { useAppDispatch } from '@/redux/store/hooks'
import { setLineageId } from '@/redux/slices/lineageId'
import { getTestIdProps } from '@/utils/general/components'
import { PAGE_NAME } from '@/constants/pages/common'
import { HOME_PAGE_COMPONENTS_NAME } from '@/constants/components'
import { dataLayerGA } from '@/analytics/GA/dataLayerHelper'
import { ACTION_NAMES } from '@/constants/analytics'

interface TopDestinationProps {
    flightsData?: IPopularDestinations
}

function TopDestinations({ flightsData }: TopDestinationProps) {
    const dispatch = useAppDispatch()
    const { destinations = [] } = flightsData || {}

    const dalayerGA = (name: string) => {
        dataLayerGA({ clicked_option_name: name, section: 'General' }, ACTION_NAMES.popularDestinationsClick)
    }
    const onPopularDestinationClick = () => {
        dispatch(setLineageId())
    }

    const flightsListsBlock = destinations.slice(0, 5).map((destination, idx) => {
        //avoiding mutating props, using .slice()
        return (
            //to copy first five elements into a new array
            <a
                key={`popular-destination-${idx}`}
                href={getPopularDestinationUrl(destination.page_url) || ''}
                onClick={() => {
                    dalayerGA(destination.name)
                }}
                className={`td-none ${idx != destinations.length - 1 ? 'mr-4' : ''}`}
                target="_blank"
                {...getTestIdProps({
                    pageName: PAGE_NAME.HOME,
                    componentName: HOME_PAGE_COMPONENTS_NAME.TOP_DESTINATION,
                    testId: `${idx}`
                })}
            >
                <Container
                    height={'235px'}
                    width={'184px'}
                    className="o-hidden br-8"
                    onClick={onPopularDestinationClick}
                >
                    <Image height={235} width={184} src={destination.image_url} alt={destination.name} />
                    <Container className="p-absolute top-destination-overlay flex flex-column flex-end pl-16">
                        <Typography variant="HM2" className="px-4" color="neutral">
                            {destination.name}
                        </Typography>
                        <Typography variant="B2" className="px-1 px-4 pb-4 pb-0" color="neutral">
                            {destination.property_count} Properties
                        </Typography>
                    </Container>
                </Container>
            </a>
        )
    })

    return <Container className="flex p-relative">{flightsListsBlock}</Container>
}

export default TopDestinations
