import React from 'react'
import { Container, Typography, Spacer } from '@cleartrip/ct-design-components'
import HomePageFaq from './HomePageFaq'
import { useRouter } from 'next/router'

const ctFeatures = [
    {
        heading: 'ClearChoice Max',
        value: ' Free cancellation or rescheduling for domestic (up to 24 hrs before departure) & international flights (up to 72 hrs before departure).'
    },
    {
        heading: 'ClearChoice Plus',
        value: 'Free date change or airline change up to 12 hrs (up to 24 hours for Air India*& Vistara*) before departure.'
    },
    {
        heading: 'Easy hotel cancellation',
        value: 'Cancel your hotel stay easily. Zero fees on hotel cancellations up to 24 hours before check-in on 20k+ hotels.'
    },
    {
        heading: 'Instant refund initiation',
        value: 'All refunds on flight and hotel cancellations are initiated instantly.'
    },
    {
        heading: 'Medi-cancel refund',
        value: 'Cancel your domestic flight booking easily on valid medical grounds and get up to ₹3500 against airline cancellation charges per passenger per segment.'
    },
    {
        heading: 'International travel insurance',
        value: 'Get stress-free coverage against a vast range of uncertainties for all international destinations at only ₹89 per user per day.'
    },
    {
        heading: 'Special Fares For Armed Personnel, Senior Citizens and Student travellers',
        value: 'Cleartrip introduces discounted fares for armed personnel, senior citizens and students on domestic flights. Additional discounts with coupon codes.'
    }
]

const ctFlightsFeatures = [
    {
        heading: 'ClearChoice Max',
        value: 'Free cancellation or rescheduling for domestic (up to 24 hrs before departure) & international flights (up to 72 hrs before departure).'
    },
    {
        heading: 'ClearChoice Plus',
        value: 'Free date change or airline change up to 12 hrs (up to 24 hours for Air India*& Vistara*) before departure.'
    },
    {
        heading: 'Medi-cancel refund',
        value: 'Cancel your domestic flight booking easily on valid medical grounds and get up to ₹3500 against airline cancellation charges per passenger per segment.'
    },
    {
        heading: 'International travel insurance',
        value: 'Get stress-free coverage against a vast range of uncertainties for all international destinations at only ₹89 per user per day.'
    }
]
const FAQs = () => {
    const router = useRouter()
    const { pathname } = router

    return (
        <>
            <Container className="pt-5" />
            {pathname === '/flights' ? (
                <>
                    <div>
                        <h3 className=" fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
                            Why Cleartrip?
                        </h3>
                        <p className="pt-4 c-dark-grey fs-14">
                            It is no longer an uphill battle to get the lowest airfare and book tickets online.
                            Cleartrip is all about making travel &nbsp;
                            <span className="fs-14 fw-600 c-neutral-900">easy, affordable</span>&nbsp; and{' '}
                            <span className="fs-14 fw-600 c-neutral-900">simple.</span> From{' '}
                            <span className="fs-14 fw-600 c-neutral-900">international</span> flights to{' '}
                            <span className="fs-14 fw-600 c-neutral-900">domestic</span> flights; from early morning
                            flights to late night flights, from cheap flights to luxurious ones. Cleartrip helps you
                            complete your flight booking in just a few clicks. Your online flight booking experience is
                            seamless with our features like:
                        </p>
                        {ctFlightsFeatures.map(feature => {
                            return (
                                <div className="pt-3">
                                    <span className="fs-14 fw-600 c-neutral-900">{feature.heading}:</span>&nbsp;
                                    <span className="c-dark-grey fs-14">{feature.value}</span>
                                </div>
                            )
                        })}
                        <p className="pt-4 c-dark-grey fs-14">
                            And with our{' '}
                            <span className="fs-14 fw-600 c-neutral-900">round-the-clock customer service,</span> we
                            ensure no queries or concerns regarding your flight tickets are left unresolved.
                        </p>
                    </div>
                    <HomePageFaq showFlightsFaq={true} />
                    <Spacer className="pt-8" />

                    <h3 className=" fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
                        What’s more?
                    </h3>
                    <p className="pt-4 c-dark-grey fs-14">
                        Flight ticket booking or planning your travel is made simpler with our round trip and multicity
                        options. When you hit enter, your search list page shows the results for both onward and return
                        in a split screen format letting you choose flights in one go for a round trip. The multicity
                        search page shows a list of complete itineraries that removes the hassle of you calculating
                        time, transfers and layovers letting you finish your online flight booking. To ensure you get
                        the best price we highlight offers, sales and other promotions on the checkout page. Post
                        booking, our portal allows for easy cancellations or amendments without having to make calls to
                        the airlines.
                    </p>
                </>
            ) : (
                <Container>
                    <Container className="row">
                        <Container className="col">
                            <Typography variant="B1" className=" fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
                                Why book on Cleartrip?
                            </Typography>
                            <Typography variant="P2" className="pt-4 c-dark-grey fs-16">
                                On Cleartrip.com, you can turn all your plans into trips. From flight ticket bookings,
                                and booking hotels online to airport, rental and outstation cab booking, with Cleartrip,
                                no travel dream is far enough. Fly to your favourite destinations with the best flight
                                offers across various airline options like IndiGo, Air India, SpiceJet, Go First,
                                AirAsia, Vistara, etc. Make the most of your holiday plans by relaxing, rejuvenating and
                                enjoying amazing leisure experiences at our vast range of hotels. From affordable and
                                budget-friendly hotels to the best 5-star properties, book your stay on Cleartrip with
                                unmissable offers. Be it for business travel or pleasure, you can now get the best deals
                                on flights and hotels. So, where to?
                            </Typography>
                        </Container>
                    </Container>
                    <div className="pt-4">
                        <div className="fs-14 fw-600 c-neutral-900">Booking flights & hotels online with Cleartrip</div>
                        <p className="c-dark-grey fs-14">
                            From queries to itineraries, for all things travel, there is Cleartrip. Checking your flight
                            updates and PNR status is easy with our simple, intuitive app and booking site. Booking
                            online hotels gets seamless with a range of choices and the greatest hotel deals.
                        </p>
                        <p className="c-dark-grey fs-14 pt-4">
                            Here’s why booking flights and hotels with Cleartrip is your Clear Advantage:
                        </p>
                        {ctFeatures.map(feature => {
                            return (
                                <div className="pt-3">
                                    <span className="fs-14 fw-600 c-neutral-900">{feature.heading}:</span>&nbsp;
                                    <span className="c-dark-grey fs-14">{feature.value}</span>
                                </div>
                            )
                        })}
                    </div>
                    <HomePageFaq showFlightsFaq={false} />
                </Container>
            )}

            <Container height="60px" />
            <Container className="pt-15 mt-10" />
        </>
    )
}

export default FAQs
