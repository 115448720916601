import React from 'react'

import { PicturePlaceHolder as Picture } from '@/assets/icons/PicturePlaceHolder'
import { Stencil } from '@/components/common/Stencil'
import { Container } from '@cleartrip/ct-design-components'

function TopDestinationsLoading() {
    return (
        <Container className="row flex-between">
            {[1, 2, 3, 4, 5].map(value => (
                <Container key={`destination-loader-${value}`} className="col-4">
                    <div className="br-4 o-hidden">
                        <Stencil height={'176px'} width="100%" shimmer className="flex flex-middle flex-center">
                            <Picture height={40} width={40} className="c-neutral-300" />
                        </Stencil>
                    </div>
                    <Container className="pt-2" />
                    <Stencil shimmer height={'20px'} width={'150px'} />
                    <Container className="py-1 pb-0" />
                    <Stencil shimmer height={'16px'} width={'100px'} />
                </Container>
            ))}
        </Container>
    )
}

export default TopDestinationsLoading
