interface CookieBannerProps {
    cookieBanner: boolean
    onClick: () => void
}
const CookieBanner = ({ cookieBanner, onClick }: CookieBannerProps) => {
    return (
        <>
            {cookieBanner && (
                <div
                    className="flex flex-middle p-fixed pt-3 pb-3 pr-4 pl-4"
                    style={{ bottom: 0, backgroundColor: 'rgba( 0, 0, 0, .9 )', width: '110%', left: 0, zIndex: '40' }}
                >
                    <div
                        className="flex flex-middle flex-between"
                        style={{ maxWidth: '83%', margin: '0 auto', marginLeft: '5%', width: '100%' }}
                    >
                        <p style={{ fontSize: '13px', color: 'white', marginRight: '20px' }}>
                            {
                                'We use cookies and may share your info with third parties to customise advertising, analyse traffic and provide social media related services.'
                            }
                            <br /> {'By using our website you accept our Cookies Policy.'}
                            <a
                                href="/privacy-policy/"
                                target="_blank"
                                style={{ color: 'white', textDecoration: 'underline' }}
                            >
                                &nbsp;Click here to know more
                            </a>
                        </p>
                        <div
                            className="closeit"
                            style={{
                                fontSize: 'xx-large',
                                color: 'white',
                                top: '-7px',
                                position: 'relative',
                                cursor: 'pointer'
                            }}
                            onClick={onClick}
                        >
                            ×
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CookieBanner
