import { ONWARD_DIR_ARROW, getAppPrefix, isEmpty, toQueryString } from '@/utils/general/browserHelper'
import { getFormattedDate } from '@/utils/general/date'
import { Container, Typography } from '@cleartrip/ct-design-components'
import React, { FC } from 'react'
import Cheveron from '@/assets/icons/CarouselRightCheveron'
import { IRecentSearchData } from '@/types/home'
import RecentSearchLoading from '@/components/home/RecentSearch/RecentSearchLoading'
import { useAppDispatch } from '@/redux/store/hooks'
import { dataLayerGA } from '@/analytics/GA/dataLayerHelper'
import { ACTION_NAMES } from '@/constants/analytics'

interface IRecentSearchProps {
    recentSearchResults: IRecentSearchData[]
    isLoading: boolean
}

const RecentSearch: FC<IRecentSearchProps> = ({ recentSearchResults, isLoading }) => {
    const dispatch = useAppDispatch()

    const handleClick = (searchQuery: IRecentSearchData) => {
        const name = `${searchQuery?.sourceCountry} ${isEmpty(searchQuery?.return_date) ? '⇄' : ONWARD_DIR_ARROW} ${
            searchQuery?.destinationCountry
        }`
        dataLayerGA({ clicked_option_name: name, section: 'Flight' }, ACTION_NAMES.searchesClick)
    }

    const formSrpUrl = (searchQuery: IRecentSearchData) => {
        const appPrefix = getAppPrefix()
        let pathname = `${appPrefix}/flights/results`
        if (searchQuery?.intl && searchQuery?.intl === 'y') {
            pathname = `${appPrefix}/flights/international/results`
        }
        const stringify = toQueryString(searchQuery)
        let search = decodeURIComponent(stringify)
        return `${pathname}?${search}`
    }

    if (isLoading) {
        return <RecentSearchLoading />
    }

    return (
        <>
            {!isEmpty(recentSearchResults) && (
                <>
                    <Typography variant="HM1" className="BigTitleBold c-neutral-900 mt-10 mb-6 pt-2">
                        Recent searches
                    </Typography>
                    <div className="flex flex-row mb-4" style={{ width: '100%', maxWidth: '100%', gap: '16px' }}>
                        {recentSearchResults?.map((recentItem, idx) => {
                            let departDate: string[] = recentItem.depart_date.split('/') || []
                            let returnDate: string[] = recentItem.return_date?.split('/') || []
                            const departYear = parseInt(departDate[2], 10)
                            const departMonth = parseInt(departDate[1], 10) - 1 // Month is 0-based in JS Date
                            const departDay = parseInt(departDate[0], 10)

                            const returnYear = returnDate.length > 0 ? parseInt(returnDate[2], 10) : undefined
                            const returnMonth = returnDate.length > 0 ? parseInt(returnDate[1], 10) - 1 : undefined // Month is 0-based in JS Date
                            const returnDay = returnDate.length > 0 ? parseInt(returnDate[0], 10) : undefined

                            return (
                                <a
                                    href={formSrpUrl(recentItem)}
                                    style={{ textDecoration: 'none', flex: 1, height: 72 }}
                                    onClick={() => handleClick(recentItem)}
                                >
                                    <Container
                                        key={`recent-search-view-${idx}`}
                                        className="flex primary-text-container-home"
                                        style={{ width: '100%', height: '100%' }}
                                    >
                                        <Container className="c-pointer px-4 py-4 flex flex-between flex-row flex-middle c-neutral-400 w-100p br-8 ba-solid bc-line-500 recentSearch">
                                            <Container className="flex flex-column  tab-typography-c-pointer">
                                                <Container className="flex" style={{ gap: '10px' }}>
                                                    <Typography
                                                        componentNode="p"
                                                        variant="P2"
                                                        className="fw-600 lh-20 fs-14"
                                                    >
                                                        {recentItem.sourceCountry} {returnDate?.length > 0 ? '⇄' : '→'}{' '}
                                                        {recentItem.destinationCountry}
                                                    </Typography>
                                                </Container>
                                                <Container>
                                                    <Typography
                                                        componentNode="p"
                                                        variant="P2"
                                                        className="fs-12 lh-16 c-neutral-400 fw-500 mt-1"
                                                    >
                                                        {getFormattedDate(
                                                            new Date(departYear, departMonth, departDay),
                                                            'DD MMM, YYYY'
                                                        )}
                                                        {returnDate?.length > 0 && (
                                                            <>
                                                                &nbsp;-&nbsp;
                                                                {getFormattedDate(
                                                                    new Date(returnYear!, returnMonth!, returnDay!),
                                                                    'DD MMM, YYYY'
                                                                )}
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Container>
                                            </Container>
                                            <Container className="flex">
                                                <Cheveron color="#808080" />
                                            </Container>
                                        </Container>
                                    </Container>
                                </a>
                            )
                        })}
                    </div>
                </>
            )}
        </>
    )
}

export default RecentSearch
