import * as React from 'react'

const Country = (props: React.SVGProps<SVGSVGElement> = {}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.5 2C4.22386 2 4 2.22386 4 2.5V13V21.5C4 21.7761 4.22386 22 4.5 22C4.77614 22 5 21.7761 5 21.5V13.5H9.61508C10.4307 13.5 11.2309 13.7217 11.9303 14.1413L12.0552 14.2162C12.91 14.7291 13.8881 15 14.8849 15H19.5C19.7761 15 20 14.7761 20 14.5V4C20 3.72386 19.7761 3.5 19.5 3.5H14.8849C14.0693 3.5 13.2691 3.27834 12.5697 2.85872L12.4448 2.78379C11.59 2.27092 10.6119 2 9.61508 2H4.5ZM9.61508 12.5H5V6H9.61508C10.4307 6 11.2309 6.22166 11.9303 6.64128L12.0552 6.71621C12.91 7.22908 13.8881 7.5 14.8849 7.5H19V14H14.8849C14.0693 14 13.2691 13.7783 12.5697 13.3587L12.4448 13.2838C11.59 12.7709 10.6119 12.5 9.61508 12.5ZM19 6.5H14.8849C14.0693 6.5 13.2691 6.27834 12.5697 5.85872L12.4448 5.78379C11.59 5.27092 10.6119 5 9.61508 5H5V3H9.61508C10.4307 3 11.2309 3.22166 11.9303 3.64128L12.0552 3.71621C12.91 4.22908 13.8881 4.5 14.8849 4.5H19V6.5Z"
            fill="#1A1A1A"
        />
    </svg>
)

export default Country
