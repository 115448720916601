export enum REQUEST_METHODS {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
}

export interface IFetchRequestConfig<
  R = unknown,
  T =
    | FetchResponseFormat.JSON
    | FetchResponseFormat.RAW
    | FetchResponseFormat.TEXT
> {
  method?: REQUEST_METHODS;
  body?: BodyInit | null | undefined;
  headers?: HeadersInit;
  /**
   * Response data type can be three types JSON,TEXT or RAW
   * since response can be of multiple type like ArrayBUffer for image or PDF raw data
   */
  responseType?: T;
  /**
   * based on init we can determine if we want to make call initially or not
   */
  init?: boolean;

  domain?: string;
  /**
   *
   * @param previousState
   * @param currentState
   * @returns
   */
  onSuccess?: (currentState: R, previousState?: R) => void;
  onError?: (error: HTTPResponseError | undefined | unknown) => void;
}

export enum FetchResponseFormat {
  JSON = "JSON",
  TEXT = "TEXT",
  RAW = "RAW",
}

export interface IBaseFetchRequestConfig<T = FetchResponseFormat>
  extends Omit<IFetchRequestConfig<T>, "init"> {
  url: string;
}

export interface IBaseResponse<T = unknown> {
  response: T;
  errorMessage: string | null;
  statusCode: number;
  ttl?: string | null;
  datasource: string | null;
}

export interface IBaseFetchType {
  <R, T extends FetchResponseFormat.JSON = FetchResponseFormat.JSON>(
    requestConfig: IBaseFetchRequestConfig<T>
  ): Promise<R>;
  <R = string, T extends FetchResponseFormat.TEXT = FetchResponseFormat.TEXT>(
    requestConfig: IBaseFetchRequestConfig<T>
  ): Promise<R>;
  <R = Response, T extends FetchResponseFormat.RAW = FetchResponseFormat.RAW>(
    requestConfig: IBaseFetchRequestConfig<T>
  ): Promise<R>;
  <R = unknown, T = unknown>(
    requestConfig: IBaseFetchRequestConfig<T>
  ): Promise<R>;
}

export class HTTPResponseError implements Error {
  errorResponse?: unknown;
  errorCode: number;
  name: string;
  message: string;
  constructor(response: Response, errorResponse?: unknown) {
    this.name = "HTTPResponseError";
    this.errorResponse = errorResponse;
    this.errorCode = response.status;
    this.message = response.statusText;
  }
}

export interface IBaseResponse<T> {
  response: T;
  errorMessage: string | null;
  statusCode: number;
  ttl?: string | null;
  datasource: string | null;
}

const commonHeaders = {
  "x-client-id": "cleartrip",
  "Content-Type": "application/json",
  "x-ct-sourceType": "MOBILE",
  "x-source-type": "B2C",
};

/**
 *
 * @param url API end point for request
 * @param method Request Method GET,POST,PUT,DELETE
 * @param body Request Body payload
 * @param headers Requst headers
 * @param responseType Response data type can be three types JSON,TEXT or RAW
 *                     since response can be of multiple type like ArrayBUffer for image or PDF raw data,
 *                     Default value is JSON
 * @returns ResponseType
 */
const baseFetch: IBaseFetchType = async <ResponseType = unknown>(
  requestConfig: IBaseFetchRequestConfig
) => {
  const {
    url,
    method,
    body,
    headers,
    domain,
    responseType,
  } = requestConfig;

  const config: RequestInit = {
    method,
    body,
    headers: {
      ...commonHeaders,
      ...headers,
    },
  };
  const requestUrl = `${domain}${url}`;
  const response = await fetch(requestUrl, config);
  if (response.ok) {
    switch (responseType) {
      case FetchResponseFormat.TEXT:
        return response.text();
      case FetchResponseFormat.RAW:
        return response;
      case FetchResponseFormat.JSON:
      default:
        return response.json() as ResponseType;
    }
  } else {
    const errorResponse = await response.json();
    throw new HTTPResponseError(response, errorResponse);
  }
};

export default baseFetch;
