import baseFetch from '@/network/baseFetch'
import React, { useEffect, useRef, useState } from 'react'
import { FetchResponseFormat } from 'server/baseFetch'

const loaderFccImage =
    'data:image/webp;base64,UklGRjABAABXRUJQVlA4TCQBAAAvZ8EoAB+goG0bhj/h3h0a/ScboPj/3cJq/uMfyBCAXNu22kYOlKB+XELoCIwjj38JqiJUSMaqQiX8dECm/wL6Ct0wRfR/ApL7B4hNk3gfub3Yjp7t2IGe9DVA8L//vlN4ax0qCm0myFt0qGcec3pCrNZjHeU0XTJdNWp8h6Gciw6ZLoeuoTB0OShVDWGqCrwqB3NVQwiCHGaW0ZigIcEquFCUsbYhrBXB2jIOTVHGvAavagpzSTkXOQRJQ87g0CRNAbywuSZ3L2iqgEPTVANelAFzVRkEVTmYqiGNJm825aJlqKYxc6w1HZpVWGuuBjM7b0NOsGitZx6r9PjYUA8xBPm2qSJms9PT0xJJz/vff1+qDvSkr8GOnu1YX08vlhyoSZP7'

interface FkResponseImages {
    [key: string]: {
        adm: {
            assets: {
                [key: string]: {
                    [x: string]: any
                    img?: { url?: string }
                    native?: { value?: string }
                }
            }[]
            impUrls?: string[]
            clickUrls?: string[]
            viewUrls?: string[]
        }
    }
}

interface ImageProps {
    fkResponseImages: FkResponseImages
    innerMargin?: number
    outterMargin?: number
}

const Image: React.FC<ImageProps> = ({ fkResponseImages, innerMargin = 0, outterMargin = 0 }) => {
    const [imgSrc, setImgSrc] = useState<string>(loaderFccImage)
    const [load, setOnLoad] = useState<boolean>(false)
    const imageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        timer = setTimeout(() => {
                            onViewFireViewEvent()
                            // observer.unobserve(entry?.target)
                        }, 1000)
                    } else {
                        if (timer) clearTimeout(timer)
                    }
                })
            },
            { threshold: 0.5 }
        )

        if (imageRef.current) observer.observe(imageRef.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    const onLoadFireImpressionEvent = async (imageArray: string) => {
        try {
            setOnLoad(true)
            if (imgSrc !== loaderFccImage) {
                const url = fkResponseImages[imageArray]?.adm?.impUrls?.[0]
                if (url)
                    await baseFetch<ResponseType>({
                        url: url,
                        responseType: FetchResponseFormat.JSON
                    })
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    const onClickFireClickEvent = async (imageArray: string) => {
        try {
            const urlResp = fkResponseImages[imageArray]?.adm?.clickUrls?.[0]?.replace(
                '[EVENTTIME]',
                Math.floor(Date.now() / 1000).toString()
            )
            if (urlResp)
                await baseFetch<ResponseType>({
                    url: urlResp,
                    responseType: FetchResponseFormat.JSON
                })
            const clickUrl = fkResponseImages[imageArray]?.adm?.assets?.[0]?.native?.value
            if (clickUrl) window.location.href = clickUrl
        } catch (ex) {
            console.error(ex)
        }
    }

    useEffect(() => {
        const imagesList = Array.from(document.querySelectorAll<HTMLImageElement>('.lazy-imageFcc'))
        if ('IntersectionObserver' in window) {
            const imageObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const image = entry.target as HTMLImageElement
                        image.src = image.dataset.src || ''
                        setImgSrc(image.src) // logic of not firing multiple impression urls during change of src and data-src
                        // imageObserver.unobserve(image);
                    }
                })
            })
            imagesList.forEach(img => imageObserver.observe(img))
        }
    }, [load])

    const onViewFireViewEvent = async () => {
        try {
            Object.keys(fkResponseImages).forEach(async imageArray => {
                const url = fkResponseImages[imageArray]?.adm?.viewUrls?.[0]
                    ?.replace('[STARTTIME]', Math.floor(Date.now() / 1000).toString())
                    ?.replace('[ENDTIME]', Math.floor(Date.now() / 1000).toString())
                if (url)
                    await baseFetch<ResponseType>({
                        url: url,
                        responseType: FetchResponseFormat.JSON
                    })
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    const numberOfImgs = Object.keys(fkResponseImages).length
    const imgWidth = ((1.0 / numberOfImgs) * 100).toString() + '%'
    const FlipkartImages = Object.keys(fkResponseImages).map((imageArray, idx) => {
        return (
            <div
                style={{
                    width: imgWidth,
                    marginRight: idx < numberOfImgs - 1 ? innerMargin : 0
                }}
                key={idx}
                ref={imageRef}
            >
                <img
                    className="lazy-imageFcc"
                    data-src={fkResponseImages[imageArray]?.adm?.assets?.[0]?.img?.url || ''}
                    alt="img"
                    src={loaderFccImage}
                    onLoad={() => onLoadFireImpressionEvent(imageArray)}
                    onClick={() => onClickFireClickEvent(imageArray)}
                    width="100%"
                    style={{ objectFit: 'cover', height: 'auto' }}
                    loading="lazy"
                />
            </div>
        )
    })

    return <div style={{ display: 'flex', margin: outterMargin }}>{FlipkartImages}</div>
}

interface FkImagesProps {
    fkResponseImages: FkResponseImages
}

const FkImages: React.FC<FkImagesProps> = ({ fkResponseImages }) => {
    const renderFccAds = () => {
        return (
            <div>
                <Image fkResponseImages={fkResponseImages} />
            </div>
        )
    }

    return <>{renderFccAds()}</>
}

export default FkImages
