import './banner.scss'
import { Cross, ClearChoiceMaxLogo } from '@/assets/icons'

const ClearMax = ({ isCross = false, onCrossClick = () => {} }) => {
    return (
        <>
            <div
                className="flex flex-middle flex-between my-4 px-4 py-4 br-8 p-relative o-hidden z-10"
                style={{ background: 'var(--Grapetini-100, #F2EDF9)', height: '90px' }}
            >
                <div>
                    <ClearChoiceMaxLogo />
                    <span className="flex flex-between statement">
                        Free cancellation or free date change starting from ₹499. T&C apply.
                    </span>
                </div>
                <div className="semicircle"></div>
                <div className="square"></div>
                <div className="circle"></div>
                <div className="flex flex-middle flex-row">
                    <a
                        className="px-3 py-2 fs-14 flex flexcenter flex-middle flex-no-shrink c-neutral-900 c-pointer td-none ba-solid  br-6 fw-600"
                        style={{ maxHeight: '32px', maxWidth: '110px' }}
                        href="/lp/clearchoice-max"
                        target="_blank"
                        title="Clearchoice Max"
                    >
                        Learn more
                    </a>
                    {isCross && (
                        <div className="ml-6 c-pointer">
                            <Cross height={20} width={20} cursor="pointer" onClick={onCrossClick} />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ClearMax
