import { useState } from 'react'
import ClearChoiceMaxBanner from './ClearChoiceMaxBanner'
const ClearChoiceMaxBannerContainer = () => {
    const [isShowClearChoiceMax, setShowClearChoiceMax] = useState(true)
    return (
        <>
            {isShowClearChoiceMax && (
                <ClearChoiceMaxBanner isCross={true} onCrossClick={() => setShowClearChoiceMax(false)} />
            )}
        </>
    )
}

export default ClearChoiceMaxBannerContainer
