import React from 'react'
import { Container } from '@cleartrip/ct-design-components'
import { Stencil } from '@/components/common/Stencil'

const RecentSearchLoading: React.FC = () => {
    return (
        <div className="pt-10">
            <Stencil className="mb-6" height={40} width={400} shimmer />
            <Container className="row flex-between ">
                {[1, 2, 3].map(value => (
                    <Container
                        key={`recent-search-flight-loader-${value}`}
                        className="flex mr-2 primary-text-container-home mb-4"
                        style={{ width: 220, height: 72 }}
                    >
                        <Container className="c-pointer px-4 py-4 flex flex-between flex-row flex-middle c-neutral-400 w-100p br-8 ba-solid bc-line-500 recentSearch">
                            <Container className="flex flex-column">
                                <Stencil className={'pb-1 '} height={12} width={168} shimmer />
                                <Stencil className={'pb-1 '} height={8} width={168} shimmer />
                            </Container>
                            <Container className="flex ml-2">
                                <Stencil height={10} width={6} shimmer />
                            </Container>
                        </Container>
                    </Container>
                ))}
            </Container>
        </div>
    )
}

export default RecentSearchLoading
