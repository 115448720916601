import React, { useEffect } from 'react'
import { Container, Typography } from '@cleartrip/ct-design-components'
import AwesomeSlider from 'react-awesome-slider'
import CoreStyles from 'react-awesome-slider/src/core/styles.scss'
import { desktopCarousels } from '@/types/home'
import CarouselLeftCheveron from '@/assets/icons/CarouselLeftCheveron'
import CarouselRightCheveron from '@/assets/icons/CarouselRightCheveron'
import { useAppDispatch } from '@/redux/store/hooks'
import { setLineageId } from '@/redux/slices/lineageId'
import { dataLayerGA } from '@/analytics/GA/dataLayerHelper'
import { ACTION_NAMES } from '@/constants/analytics'
import { queryFormat } from '@/components/oldComponents/Dropdown/HomeSearchContainer'

const FlightDeals = ({
    searchParams,
    desktopCarousels
}: {
    searchParams: queryFormat
    desktopCarousels: desktopCarousels
}) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        let custom = 3
        if (desktopCarousels?.length > 0) {
            custom = desktopCarousels.length
        }
        document.documentElement.style.setProperty('--arrow-offer-value', `${(153 - ((custom - 1) * 11 + 17)) / 2}px`)
    }, [desktopCarousels])
    const onDealsClick = () => {
        dispatch(setLineageId())
    }

    const handleClick = (event: string) => {
        dataLayerGA(
            {
                clicked_option_name: event,
                section: 'Flights',
                flight_type: searchParams?.intl === 'y' ? 'International' : 'Domestic'
            },
            ACTION_NAMES.offerKnowMore
        )
    }
    return (
        <>
            {desktopCarousels?.length ? (
                <>
                    <div className="flex flex-between flex-middle mb-4 mt-6">
                        <div className="flex flex-column">
                            <p style={{ fontSize: '18px' }} className={'c-neutral-900 fw-600 lh-24'}>
                                More offers
                            </p>
                        </div>
                        <div className="flex flex-column">
                            <a
                                href={'/all-offers/?categories=flights'}
                                target="_blank"
                                className="c-secondary-500 fs-14 fw-600 td-none td-underline"
                            >
                                {'View all'}
                            </a>
                        </div>
                    </div>
                    <Container className="ba-solid bc-line-500 br-8 pt-6 pb-6" onClick={onDealsClick}>
                        <AwesomeSlider
                            bullets={true}
                            cssModule={CoreStyles}
                            buttonContentLeft={<CarouselLeftCheveron color="#808080" />}
                            buttonContentRight={<CarouselRightCheveron color="#808080" />}
                            organicArrows={false}
                        >
                            {desktopCarousels?.map((item, idx) => (
                                <Container key={`${item.COUPON_CODE}${idx}`} className="flex flex-column h-100p">
                                    <Typography variant={'L1'} className="c-neutral-900 mb-1 px-6 td-none lh-20">
                                        {item.HEADING}
                                    </Typography>
                                    {item.DESC && (
                                        <Typography variant={'B2'} className="fs-3 px-6 c-neutral-900 fw-500 mb-2 mt-4">
                                            {item.DESC}
                                        </Typography>
                                    )}
                                    {item.COUPON_CODE && (
                                        <Typography variant={'P2'} className="fs-3 px-6 c-neutral-900 fw-400">
                                            {item.COUPON_CODE}
                                        </Typography>
                                    )}
                                    <a
                                        onClick={() => handleClick(item.HEADING)}
                                        href={item.LINK}
                                        target="_blank"
                                        className="c-secondary-500 td-none mt-3 px-6 flex"
                                    >
                                        <span className="pr-1 fs-14 fw-600 lh-24"> {'Know more'}</span>
                                    </a>
                                </Container>
                            ))}
                        </AwesomeSlider>
                    </Container>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default FlightDeals
