import React from 'react'
import { Container } from '@cleartrip/ct-design-components'
import { IBanner, LoginBanner } from '@/types/home'
import { useAppDispatch } from '@/redux/store/hooks'
import { setLineageId } from '@/redux/slices/lineageId'
import { getTestIdProps } from '@/utils/general/components'
import { PAGE_NAME } from '@/constants/pages/common'
import { HOME_PAGE_COMPONENTS_NAME } from '@/constants/components'
interface IImageGridProps {
    images: IBanner[] | LoginBanner[]
    innerMargin?: number
    outterMargin?: number
    handleClick?: (url?: string) => void
    imageAlt?: string
}

const ImageGrid = ({ images = [], innerMargin = 0, outterMargin = 0, handleClick, imageAlt }: IImageGridProps) => {
    const dispatch = useAppDispatch()

    const handleImageClick = (url?: string) => () => {
        dispatch(setLineageId())
        url && handleClick?.(url)
    }

    const numberOfImgs = images.length
    const imgWidth = ((1.0 / numberOfImgs) * 100)?.toString() + '%'
    const Images = images.map((ele, idx) => {
        return (
            <Container
                css={{
                    width: imgWidth,
                    marginRight: idx < numberOfImgs - 1 && innerMargin,
                    visibility: ele.src ? 'visible' : 'hidden'
                }}
                key={idx}
            >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    src={ele.src}
                    width="100%"
                    onClick={handleImageClick(ele.url)}
                    alt={`${imageAlt} ${idx}`}
                    {...getTestIdProps({
                        pageName: PAGE_NAME.HOME,
                        componentName: HOME_PAGE_COMPONENTS_NAME.BSB_BANNER,
                        testId: `${idx}`
                    })}
                />
            </Container>
        )
    })

    return images.length ? <Container css={{ display: 'flex', margin: outterMargin }}>{Images}</Container> : <></>
}

export default ImageGrid
