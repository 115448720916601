import * as React from 'react'

const LocationLocality = (props: React.SVGProps<SVGSVGElement> = {}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5285 3.95898C12.8563 2.50846 15.1421 2.50846 16.4699 3.95898C17.4498 5.0295 17.6289 6.6093 16.9134 7.87197L14.8692 11.4793C14.4862 12.1552 13.5123 12.1552 13.1292 11.4793L11.0851 7.87197C10.3696 6.6093 10.5486 5.0295 11.5285 3.95898ZM15.7323 4.63419C14.8009 3.61673 13.1975 3.61673 12.2662 4.63419C11.5788 5.3851 11.4532 6.49326 11.9551 7.37895L13.9992 10.9862L16.0434 7.37895C16.5452 6.49326 16.4197 5.3851 15.7323 4.63419ZM7.86582 2.96684C7.97221 2.94025 8.08412 2.94606 8.18718 2.98353L10.1872 3.71081C10.4726 3.81461 10.6199 4.13018 10.5161 4.41565C10.4123 4.70112 10.0967 4.84839 9.81126 4.74458L8.54922 4.28566V17.604L13.4492 19.2373V13.5004C13.4492 13.1967 13.6955 12.9504 13.9992 12.9504C14.303 12.9504 14.5492 13.1967 14.5492 13.5004V19.4027L19.4492 18.9943V5.14151L18.5829 5.27479C18.2826 5.32098 18.0018 5.11505 17.9556 4.81482C17.9094 4.5146 18.1154 4.23377 18.4156 4.18758L19.9156 3.95682C20.0742 3.93241 20.2355 3.9785 20.3573 4.08299C20.4791 4.18748 20.5492 4.33995 20.5492 4.50042V19.5004C20.5492 19.7865 20.33 20.0248 20.0449 20.0485L14.0513 20.548C14.0341 20.5496 14.0168 20.5504 13.9992 20.5504L13.9892 20.5503C13.9825 20.5502 13.9758 20.55 13.9692 20.5496C13.9147 20.5467 13.8623 20.5358 13.8132 20.5182L8.01002 18.5838L4.19234 20.0154C4.02343 20.0787 3.83421 20.0552 3.68594 19.9525C3.53768 19.8497 3.44922 19.6808 3.44922 19.5004V4.50042C3.44922 4.24804 3.62098 4.02805 3.86582 3.96684L7.86582 2.96684ZM4.54922 4.92985L7.44922 4.20485V17.6193L4.54922 18.7068V4.92985ZM13.9992 7.00042C14.2807 7.00042 14.5177 6.90412 14.7103 6.71153C14.9029 6.51894 14.9992 6.2819 14.9992 6.00042C14.9992 5.71894 14.9029 5.4819 14.7103 5.28931C14.5177 5.09672 14.2807 5.00042 13.9992 5.00042C13.7177 5.00042 13.4807 5.09672 13.2881 5.28931C13.0955 5.4819 12.9992 5.71894 12.9992 6.00042C12.9992 6.2819 13.0955 6.51894 13.2881 6.71153C13.4807 6.90412 13.7177 7.00042 13.9992 7.00042Z"
            fill="#1A1A1A"
        />
    </svg>
)

export default LocationLocality
