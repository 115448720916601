import React, { useEffect } from 'react'
import AwesomeSlider from 'react-awesome-slider'
import CoreStyles from 'react-awesome-slider/src/core/styles.scss'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import { Container } from '@cleartrip/ct-design-components'
import Image from 'next/image'

import './_carousel.scss'

import { IBanner } from '@/types/home'
import CarouselRightCheveron from '@/assets/icons/CarouselRightCheveron'
import CarouselLeftCheveron from '@/assets/icons/CarouselLeftCheveron'
import { useAppDispatch } from '@/redux/store/hooks'
import { setLineageId } from '@/redux/slices/lineageId'

const transitionInterval = 5000 //in milliseconds

const CarousalOffer = (props: { enabled?: boolean; banners?: IBanner[] }) => {
    const dispatch = useAppDispatch()
    const { banners = [], enabled } = props || {}

    useEffect(() => {
        let custom = 2
        if (banners?.length) {
            custom = banners.length
        }
        document.documentElement.style.setProperty('--arrow-value', `${(153 - ((custom - 1) * 11 + 17)) / 2}px`)
    }, [banners])

    const AutoplaySlider = withAutoplay(AwesomeSlider)

    const showCarouselButtons = banners.length > 1

    const onBannerClick = () => {
        dispatch(setLineageId())
    }

    return (
        <>
            {enabled && banners?.length ? (
                <>
                    <Container className="bc-neutral-100 br-8 mt-4 o-hidden carousalImg">
                        <AutoplaySlider
                            play={showCarouselButtons}
                            cancelOnInteraction={false}
                            interval={transitionInterval}
                            cssModule={CoreStyles}
                            bullets={showCarouselButtons}
                            buttonContentLeft={showCarouselButtons && <CarouselLeftCheveron />}
                            buttonContentRight={showCarouselButtons && <CarouselRightCheveron />}
                            organicArrows={false}
                        >
                            {banners.map((item, idx) => (
                                <Container className="br-4" key={idx} onClick={onBannerClick}>
                                    <a href={item.url} target="_blank">
                                        {/* TODO: check fastui url config for urls */}
                                        <Image src={item.src} alt={'img'} width={260} height={204} />
                                    </a>
                                </Container>
                            ))}
                        </AutoplaySlider>
                    </Container>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default CarousalOffer
