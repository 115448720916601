import { useState } from 'react'
import { Container, Typography } from '@cleartrip/ct-design-components'
import { HAMBURGER_OPTIONS } from '@/constants/components'
import Image from 'next/image'

const Hamburger = ({uhpCookie } : {uhpCookie: string | boolean}) => {
    const [hovered, setHovered] = useState(false)
    const toggleHover = (isOffersStrip: boolean) => () => {
        isOffersStrip && setHovered(!hovered)
    }
    const HAMBURGER_OPTIONS_FILTERED = HAMBURGER_OPTIONS.filter((item) => uhpCookie == 'true' ? true : item.text !== 'Home' )
    return (
        <>
            <Container className="h-5" />
            <Container marginTop="8px" marginBottom="8px" display="block">
                {HAMBURGER_OPTIONS_FILTERED.map(({ isSelected, icon: Icon, text, link }, index) => {
                    const isOffersStrip = text === HAMBURGER_OPTIONS[4].text
                    const isHotels = text === HAMBURGER_OPTIONS[2].text
                    return (
                        <Container
                            key={`humberger-option-${index}`}
                            onMouseEnter={toggleHover(isOffersStrip)}
                            onMouseLeave={toggleHover(isOffersStrip)}
                        >
                            <a className="td-none" href={link}>
                                <Container
                                    className={`flex pl-2 mb-4 br-6 flex-middle h-10 c-pointer ${
                                        isSelected ? '' : isOffersStrip ? 'nav' : 'nav svg'
                                    } ${isHotels ? 'hotelsnav' : ''}`}
                                    backgroundColor={isSelected ? '#d6e8fc' : ''}
                                >
                                    <Icon
                                        className={isOffersStrip ? '' : 'fill'}
                                        stroke={isOffersStrip ? (hovered ? '#36c' : '#1a1a1a') : ''}
                                    />
                                    <Container className="flex ml-4 mr-2 flex-1">
                                        <Typography
                                            variant="B1"
                                            className={`${isSelected ? 'lh-20' : 'nav lh-20'} flex flex-middle`}
                                            color={isSelected ? 'link' : 'primary'}
                                        >
                                            {text}
                                            {text === 'Bus' ? (
                                                <Image
                                                    src="https://rukmini-ct.flixcart.com/q_100/staticresource-prod/images/bus/BusNew.png"
                                                    height={'18'}
                                                    width={'37'}
                                                    alt="new"
                                                    className="ml-2"
                                                />
                                            ) : null}
                                        </Typography>
                                    </Container>
                                </Container>
                            </a>
                        </Container>
                    )
                })}
            </Container>
        </>
    )
}

export default Hamburger
