import { useEffect } from 'react'
import { Container } from '@cleartrip/ct-design-components'

import HomeMain from '@/components/home/HomeMain'
import HomeHeader from '@/components/home/HomeHeader'
import Footer from '@/components/common/Footer/index'
import { triggerRavenHomePageload } from '@/analytics/raven/ravenUtils'
import FooterMock from '@/mocks/footer.json'

import { getActionPayload } from '@/utils/general/actions'
import { ActionMapper } from '@/types'
import { useAppDispatch } from '@/redux/store/hooks'
import { isUserSignedIn } from '@/utils/general/user'

const HomeContainer: React.FC<{ isSignedIn: boolean , uhpCookie : boolean | string}> = ({ isSignedIn , uhpCookie}) => {
    const globalDispatcher = useAppDispatch()
    useEffect(() => {
        try {
            if (!isUserSignedIn()) {
                const obj = JSON.parse(window?.sessionStorage.getItem('loginPopup') || '{}')
                if (!obj?.flightHome) {
                    window.sessionStorage.setItem(
                        'loginPopup',
                        JSON.stringify({
                            ...obj,
                            flightHome: 'true',
                            flightIternary: 'true',
                            hotelIternary: 'true',
                            hotelHome: 'true'
                        })
                    )
                    globalDispatcher(getActionPayload('OPEN_MODAL', { type: ActionMapper.OPEN_LOG_IN_MODAL }))
                }
            }
            triggerRavenHomePageload()
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <Container>
            <header className="bs-shadow-hbs bc-line-500 p-relative bg-white flex flex-middle h-18">
                <HomeHeader isSignedIn={isSignedIn} />
            </header>
            <main>
                <HomeMain uhpCookie={uhpCookie}/>
            </main>
            {/* @ts-ignore */}
            <Footer data={{ data: FooterMock.slotData.data }} />
        </Container>
    )
}
export default HomeContainer
